import React from "react";
import Form, { FormProps } from "../form";
import { useDomainContext } from "@hooks/useDomainContext";
import { Field, FieldTypes } from "@lib/shared/types";
import styles from "./styles.module.scss";
import { DomainForm } from "src/api";
import { formatPhoneNumber, getPhoneNumber } from "@lib/sharedUtils";
import AffiliateOfferUrl from "../affiliateOfferUrl";

const FormOfferModal = (props: FormProps) => {
    const PII_FIELDS = [
        "zipCode",
        "streetAddress",
        "firstName",
        "lastName",
        "phoneNumber",
        "email",
    ];
    const { goToThankYouPage, colors, form } = props;
    const { form: domainContextForm, exitModalOffer } = useDomainContext();
    const offer = exitModalOffer?.[0];
    const _form = form ?? (domainContextForm as DomainForm);
    const { textBlocks } = _form;
    const phoneNumber = getPhoneNumber(offer);
    const result = {
        ..._form,
        steps: [
            {
                ..._form?.steps[0],
                fields: [],
            },
            {
                ..._form?.steps.find((step) =>
                    step.fields.find((field) =>
                        PII_FIELDS.includes(field.codeName),
                    ),
                ),
                fields: [],
            },
        ],
    };
    const marketingMessage = textBlocks.find(
        (item) => item.position === "form-inside-modal-marketing-message",
    );

    const updateFieldType = (field: Field): FieldTypes => {
        return field.fieldType === "radio"
            ? "select"
            : field.fieldType === "checkbox"
            ? "multiSelect"
            : field.fieldType;
    };

    _form?.steps.forEach((step) => {
        step.fields.forEach((field) => {
            if (PII_FIELDS.includes(field.codeName)) {
                result.steps[1].fields.push({
                    ...field,
                    fieldType: updateFieldType(field),
                });
            } else {
                result.steps[0].fields.push({
                    ...field,
                    fieldType: updateFieldType(field),
                });
            }
        });
    });

    return (
        <div className="flex flex-col sm:flex-row">
            <div className="w-full flex flex-col items-center justify-between">
                <div className="flex flex-col items-center justify-center gap-2 sm:gap-4 mb-6 px-3 flex-1 h-full">
                    <div className="max-w-[300px]">
                        <img
                            src={offer?.logo?.fullPath}
                            alt="endurance offer 300 off"
                            className="w-full"
                        />
                    </div>
                    {marketingMessage && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: marketingMessage?.text,
                            }}
                        />
                    )}
                    <AffiliateOfferUrl
                        zone={"exitModal"}
                        className="bg-[#FF8800] px-8 py-2 flex items-center gap-4 font-bold text-xl"
                        offer={offer}
                        target="_blank"
                        customHref={
                            phoneNumber ? `tel:${phoneNumber ?? ""}` : undefined
                        }
                    >
                        {formatPhoneNumber(phoneNumber) ?? "Get a Quote"}
                    </AffiliateOfferUrl>
                    {phoneNumber && (
                        <AffiliateOfferUrl
                            zone={"exitModal"}
                            offer={offer}
                            target="_blank"
                            className="text-[#31AAFC] inline-block border-b border-b-[#31AAFC]"
                        >
                            <span>Get a Quote</span>
                        </AffiliateOfferUrl>
                    )}
                </div>
                <img
                    src="https://cdn-staging.trafficbox.com/attachments/2024/7/d352c297-3596-4526-a858-316af1d32454.jpg"
                    alt="endurance offer 300 off"
                />
            </div>
            <div className="w-full bg-[#f8f8f8] relative">
                <Form
                    classes={{
                        formClassName: styles["form"],
                        stepClassName: styles["step"],
                        fieldsClassName: {
                            radio: styles["radio-field"],
                        },
                        formButtonClassName: styles["form-button"],
                        formFieldLabelClassName: styles["field-label"],
                        stepTitleClassName: styles["step-title"],
                        fieldNoteClassName: styles["field-note"],
                        formFieldClassName: styles["form-field"],
                        stepDescriptionClassName: styles["step-description"],
                        sellingPointClassName: styles["selling-point"],
                        formStepInnerClassName: styles["steps-inner"],
                    }}
                    showProgress={false}
                    colors={colors}
                    form={result as DomainForm}
                    dynamicThankYouFields={[
                        "firstName",
                        "manufacturingYear",
                        "vehicleMake",
                        "vehicleModel",
                    ]}
                    isFormInsideOffersModal
                    goToThankYouPage={goToThankYouPage}
                />
            </div>
        </div>
    );
};

export default FormOfferModal;
